<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name (En)"
            rules="required"
          >
            <b-form-group
              label="Name (En)"
              label-for="name_en"
            >
              <b-form-input
                id="name_en"
                v-model="zonesForm.name_en"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name (Ar)"
            rules="required"
          >
            <b-form-group
              label="Name (Ar)"
              label-for="name_ar"
            >
              <b-form-input
                id="name_ar"
                v-model="zonesForm.name_ar"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Price"
            rules="required"
          >
            <b-form-group
              label="Price"
              label-for="Price"
            >
              <b-form-input
                id="Price"
                v-model="zonesForm.price"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Price"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Delivery Days"
            rules="required"
          >
            <b-form-group
              label="Delivery Days"
              label-for="Price"
            >
              <b-form-input
                id="Delivery Days"
                v-model="zonesForm.delivery_days"

                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Delivery Days"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Countries"
          >
            <b-form-group
              label="Countries"
              label-for="Countries"
            >
              <v-select
                v-model="zonesForm.country_id"
                :options="countries"
                :filterable="false"
                label="name"
                :reduce="countries => countries.id"
                @search="searchCountries"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Governorates"
          >
            <b-form-group
              label="Governorates"
              label-for="Governorates"
            >
              <v-select
                :key="zonesForm.governments"
                v-model="zonesForm.governments"
                :options="governorates"
                multiple
                :filterable="false"
                label="name"
                :reduce="governorates => governorates.id"
                @search="searchGovernorates"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="errorMsg"
          cols="12"
        >
          <p class="text-danger">
            {{ errorMsg }}
          </p>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addGovernorates()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addGovernorates()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>

      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import vSelect from 'vue-select'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import handleFormData from '@/global-compositions/handleFormData'

// eslint-disable-next-line no-unused-vars

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      required,
      id: this.$store.state.generalIds.id,
      selected: [],
      allSelected: [],
      indeterminate: false,
      allPermissions: [],
      users: [],
      countries: [],
      governorates: [],
      countryId: '',
      loader: false,
      errorMsg: '',
      validationErrors: {},
      errors: {},
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const zonesForm = ref({ })
    // eslint-disable-next-line camelcase
    const { formData, setFormData } = handleFormData()

    return {
      getValidationState,
      zonesForm,
      formData,
      setFormData,
    }
  },
  mounted() {
    this.showZones()
    this.getCountries()
    this.getGovernorates()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    toggleAll(value, key) {
      if (this.allSelected[key]) {
        // eslint-disable-next-line array-callback-return
        value.items.map(item => {
          this.zonesForm.permission.push(item.id)
        })
      } else {
        // eslint-disable-next-line array-callback-return
        value.items.map(item => {
          this.zonesForm.permission.splice(this.zonesForm.permission.indexOf(item.id), 1)
        })
      }
    },
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.flag_image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.zonesForm.flag_image = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.flag_image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    async searchCountries(searchQuery) {
      await axios.get(`admin/countries?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.countries = res.data?.data.countries
        }
      })
    },
    async searchGovernorates(searchQuery) {
      await axios.get(`admin/governorates?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.governorates = res.data?.data.governorates
        }
      })
    },
    showZones() {
      if (this.$route.params.id) {
        axios.get(`admin/zones/${this.$route.params.id}`).then(res => {
          this.zonesForm = res.data.data.zone
        })
      } else {
        return false
      }
      return true
    },
    async getCountries() {
      await axios.get('admin/countries').then(res => {
        if (res.status === 200) {
          this.countries = res.data?.data.countries
        }
      })
    },
    async getGovernorates() {
      await axios.get('admin/governorates').then(res => {
        if (res.status === 200) {
          this.governorates = res.data?.data.governorates
        }
      })
    },

    addGovernorates() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.zonesForm) {
          if (key !== 'governments') {
            formData.append(key, this.zonesForm[key])
          } else {
            this.zonesForm.governments.forEach(government => {
              formData.append('governments[]', government)
            })
          }
        }
        axios.post(`admin/zones/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'shipping-zones' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.zonesForm) {
          if (key !== 'governments') {
            formData.append(key, this.zonesForm[key])
          } else {
            this.zonesForm.governments.forEach(government => {
              formData.append('governments[]', government)
            })
          }
        }

        this.loader = true
        axios.post('admin/zones', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'shipping-zones' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Somthing Went Wrong',
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          } else if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },

  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
